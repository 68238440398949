.simple-verify {
  box-sizing: border-box;
  line-height: 1;
  position: relative;
  -webkit-user-select: none; }
  .simple-verify .verify-tips {
    widows: 100%;
    height: 100%;
    color: #9CA0A7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    pointer-events: none; }
  .simple-verify .verify-box {
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    overflow: hidden; }
  .simple-verify .veriry-slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .1s linear, transform .3s ease; }
  .simple-verify .verify-bar {
    position: absolute;
    left: -1px;
    top: -1px;
    width: 50px;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: transform .3s ease;
    touch-action: none; }
    .simple-verify .verify-bar .icon {
      width: 44px;
      height: 29px;
      box-shadow: rgba(113, 114, 119, 0.3) 0 3px 10px;
      border-radius: 4px; }
  .simple-verify .verify-success-tips {
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    opacity: 0;
    transition: opacity .1s linear;
    pointer-events: none; }
    .simple-verify .verify-success-tips span {
      width: 20px;
      height: 20px;
      margin-right: 8px; }
